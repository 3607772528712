<template>
  <v-container class="pt-4">
    <div v-if="!overlay">
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="d-flex align-center rounded-lg pa-2 px-4" flat>
            <h4 class="">
              {{ mailInfo.mail ? mailInfo.mail.fullSectionPath : "" }}
            </h4>
            <v-spacer></v-spacer>
            <v-btn tile color="primary" @click="back()" class="rounded-xl">
              {{ $t("back") }}
              <v-icon right> mdi-arrow-left </v-icon>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="mailInfo.mail.replyOnGuid">
        <v-col cols="12" md="12">
          <v-expansion-panels v-model="gdFather" flat outlined>
            <v-expansion-panel @click="getGdFatherInfo">
              <v-expansion-panel-header
                ><h3>
                  {{ $t("mail.previousMail") }} ( {{ mailInfo.mail.replyOnTitle }} )
                </h3></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-card :loading="cardLoad" flat outlined>
                  <v-card-text v-if="gfMailInfo.mail">
                    <v-row>
                      <v-col class="pa-0 pb-4">
                        <v-list-item two-line>
                          <v-list-item-avatar>
                            <img src="../../assets//user.svg" />
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{ gfMailInfo.mail.fromName }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              gfMailInfo.mail.fromCode
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col :class="$i18n.locale == 'ar' ? 'text-left ' : 'text-right '">
                        <v-chip class="py-6" label outlined>
                          <v-tooltip top>

                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                {{gfMailInfo.mail.datex2 }}
                              <v-icon class="px-1"> mdi-calendar-range </v-icon>

                              </div>

                            </template>
                            <span>{{
                              gfMailInfo.mail.datex
                            }}</span>

                          </v-tooltip>
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="">
                        <v-alert text dense color="primary" border="left">
                          <h3 class="py-2 text--primary">
                            {{ gfMailInfo.mail.title }}
                          </h3>
                          <div class="pb-4">
                            {{ gfMailInfo.mail.subjectName }}
                          </div>
                          <div class="text--primary" v-html="gfMailInfo.mail.detailsHTML" />
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions v-if="gfMailInfo.mail">
                    <!-- <v-btn
                      v-if="gfMailInfo.mail.attachedFileUrl"
                      class="mx-2 mb-2"
                      outlined
                      color="teal"
                      @click="installFile(gfMailInfo.mail)"
                    >
                      {{ $t("mail.DownloadTheAttachment") }}
                      ({{ $t("fileType") }}
                      {{ get_url_extension(gfMailInfo.mail.attachedFileUrl) }})
                      <v-icon class="px-2"> mdi-file-download-outline </v-icon>
                    </v-btn> -->

                    <download-file
                      v-if="gfMailInfo.mail.attachedFileUrl"
                      :fileUrl="gfMailInfo.mail.attachedFileUrl"
                    ></download-file>
                  </v-card-actions>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="mx-auto" flat outlined>
            <v-card-text>
              <v-row>
                <v-col class="pa-0 pb-4">
                  <v-list-item two-line>
                    <v-list-item-avatar>
                      <img src="../../assets//user.svg" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ mailInfo.mail.fromName }}</v-list-item-title>
                      <v-list-item-subtitle>{{ mailInfo.mail.fromCode }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col :class="$i18n.locale == 'ar' ? 'text-left ' : 'text-right '">

                       <v-chip class="py-6" label outlined>
                          <v-tooltip top>

                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                {{mailInfo.mail.datex2 }}
                              <v-icon class="px-1"> mdi-calendar-range </v-icon>

                              </div>

                            </template>
                            <span>{{
                              mailInfo.mail.datex
                            }}</span>

                          </v-tooltip>
                        </v-chip>


                </v-col>
              </v-row>
              <v-row>
                <v-col class="">
                  <v-alert text dense color="primary" border="left">
                    <h3 class="py-2 text--primary">
                      {{ mailInfo.mail.title }}
                    </h3>
                    <div class="pb-4">{{ mailInfo.mail.subjectName }}</div>
                    <div class="text--primary" v-html="mailInfo.mail.detailsHTML" />
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <!-- <v-btn
                v-if="mailInfo.mail.attachedFileUrl"
                class="mx-2 mb-2"
                outlined
                color="teal"
                @click="installFile(mailInfo.mail)"
              >
                {{ $t("mail.DownloadTheAttachment") }}
                ({{ $t("fileType") }}
                {{ get_url_extension(mailInfo.mail.attachedFileUrl) }})

                <v-icon class="px-2"> mdi-file-download-outline </v-icon>
              </v-btn> -->

              <download-file
                v-if="mailInfo.mail.attachedFileUrl"
                :fileUrl="mailInfo.mail.attachedFileUrl"
              ></download-file>
              <v-spacer></v-spacer>
              <v-btn
                v-if="mailInfo.mail.mailType === 0"
                class="mx-2 mb-2"
                outlined
                color="primary"
                @click="replyDialog(mailInfo.mail)"
              >
                {{ $t("mail.reply") }}
                <v-icon>mdi-reply</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-expansion-panels v-model="panel" multiple flat outlined>
            <v-expansion-panel v-for="(item, i) in mailInfo.replies" :key="i">
              <v-expansion-panel-header>
                <h3 class="headerClass py-2">{{ item.title }}</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card :loading="cardLoad" flat outlined>
                  <v-card-text v-if="mailInfo.replies">
                    <v-row>
                      <v-col class="pa-0 pb-4">
                        <v-list-item two-line>
                          <v-list-item-avatar>
                            <img src="../../assets//user.svg" />
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{ mailInfo.mail.to[0].toName }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              mailInfo.mail.to[0].toCode
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col :class="$i18n.locale == 'ar' ? 'text-left ' : 'text-right '">

                        <v-chip class="py-6" label outlined>
                          <v-tooltip top>

                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                {{item.datex2 }}
                              <v-icon class="px-1"> mdi-calendar-range </v-icon>

                              </div>

                            </template>
                            <span>{{
                              item.datex
                            }}</span>

                          </v-tooltip>
                        </v-chip>


                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="">
                        <v-alert text dense color="primary" border="left">
                          <h3 class="py-2 text--primary">{{ item.title }}</h3>
                          <div class="pb-4">
                            {{ mailInfo.mail.subjectName }}
                          </div>
                          <div class="text--primary" v-html="item.detailsHTML" />
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions v-if="mailInfo.replies">
                    <v-btn class="mx-2 mb-2" outlined color="primary" @click="openReply(item)">
                      {{ $t("mail.MailView") }}
                      <v-icon class="px-2"> mdi-eye </v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
    <ReplyMail ref="ReplyMailComponent" v-on:send-result-values="getMailInfo"></ReplyMail>


  </v-container>
</template>

<script>
import moment from "moment";
import DownloadFile from "../../components/downloadFile.vue";
import ReplyMail from "../../components/mails/replyMail.vue";

export default {
  components: {
    ReplyMail,
    DownloadFile
  },
  props: ["id", "type"],
  data() {
    return {
      cardLoad: false,
      overlay: false,
      mailInfo: {},
      panel: [],
      gdFather: [],
      gfMailInfo: [],
      items: 5
    };
  },
  created() {
    this.getMailInfo();
  },
  computed: {
    replace() {
      setTimeout(() => {
        this.getMailInfo();
      }, 1000);
      return this.id;
    }
  },
  methods: {
    replyDialog(data) {
      // console.log(data);
      this.$refs.ReplyMailComponent.setValue(data.guid);
    },
    openReply(item) {
      this.$router.push({
        path: `/showMail=${item.guid}&${item.mailType}`,
        params: { id: item.guid, type: item.mailType }
      });
      setTimeout(() => {
        this.getMailInfo();
      }, 1000);
    },
    getGdFatherInfo() {
      if (this.gdFather) {
        // console.log(this.mailInfo.mail.replyOnGuid);
        this.cardLoad = true;
        const t = this.type == 1 ? 0 : 1;
        this.axios
          .get(`Mails/Preview?mailGuid=${this.mailInfo.mail.replyOnGuid}&mailType=${t}`)
          .then(res => {
            this.gfMailInfo = res.data.data;

            this.gfMailInfo.mail.datex2 = moment(this.gfMailInfo.mail.datex).fromNow();
            this.gfMailInfo.mail.datex = moment(this.gfMailInfo.mail.datex).format("YYYY/MM/DD - h:mm a ");
          })
          .catch(() => {})
          .finally(() => {
            this.cardLoad = false;
          });
      }
    },
    get_url_extension(url) {
      return url
        .split(/[#?]/)[0]
        .split(".")
        .pop()
        .trim()
        .toLowerCase();
    },
    installFile(data) {
      window.open(`${data.attachedFileUrl}`);
      return;
      // console.log(data);
      this.axios({
        url: `${data.attachedFileUrl}`,
        method: "GET",
        responseType: "blob"
      })
        .then(response => {
          const blob = new Blob([response.data], {
            type: response.data.type
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = data.title;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("mail.TheFileDoesNotExist"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close")
          });
        });
    },
    getMailInfo() {
      this.overlay = true;
      this.axios
        .get(`Mails/Preview?mailGuid=${this.id}&mailType=${this.type}`)
        .then(res => {
          // console.log(res);
          this.mailInfo = res.data.data;
                 this.mailInfo.mail.datex2 = moment(this.mailInfo.mail.datex).fromNow();
            this.mailInfo.mail.datex = moment(this.mailInfo.mail.datex).format("YYYY/MM/DD - h:mm a ");

             this.mailInfo.replies.forEach((e) => {
            e.datex2 = moment(e.datex).fromNow();
            e.datex = moment(e.datex).format("YYYY/MM/DD - h:mm a ");
           // e.title= e.title.length>100?e.title.substring(0,99):e.title
          });
        })
        .catch(() => {})
        .finally(() => {
          this.overlay = false;
        });
    },
    activated() {
      this.getMailInfo();
    },
    beforeRouteEnter(to, from, next) {},
    back() {
      this.$router.go(-1);
      setTimeout(() => {
        this.getMailInfo();
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
